log('store.js')

import Vue from 'vue';
import Vuex from 'vuex';

import axios from '@/js/axios';
import utils from '@/js/utils';

Vue.use(Vuex);

function log(title, item) {
    // console.log(title, item);
}

export default new Vuex.Store({
    state: {
        pgs: [],
        qrks: []
    },
    mutations: {
        setPages(state, payload) {
            log('vuex mutations setPages', payload);
            state.pgs = payload;
        },
        setQuarks(state, payload) {
            log('vuex mutations setQuarks', payload);
            state.qrks = payload;
        }
    },
    actions: {
        async getPages() {
            let url = 'pages';
            await axios.getData(url).then(response => {
                log('vuex actions getpages', response);
                utils.sortByRank(response);
                this.commit('setPages', response);
            });
        },

        async getQuarks({ }, id) {
            this.commit('setQuarks', []);
            let url = 'rows/page_id/' + id;
            log('url', url);
            await axios.getData(url).then(response => {
                log('vuex actions getQuarks', response);

                if (response.length > 0) {
                    // sort by rank
                    response.sort(function (a, b) {
                        return a.rank - b.rank;
                    });
                }

                this.commit('setQuarks', response);
            });
        }
    },
    getters: {
        pages: state => {
            log('vuex getters pages')
            return state.pgs;
        },

        getPageId: state => route => {
            log('vuex getters page-id')
            let id = -1;
            for (let i = 0; i < state.pgs.length; i++) {
                // log(state.pgs[i].path);
                if (route == state.pgs[i].path) {
                    id = state.pgs[i].id;
                    break;
                }
            }
            return id;
        },

        page: state => route => {
            log('vuex getters page-id')
            let currentPage;
            for (let i = 0; i < state.pgs.length; i++) {
                // log(state.pgs[i].path);
                if (route == state.pgs[i].path) {
                    currentPage = state.pgs[i];
                    break;
                }
            }
            return currentPage;
        },

        quarks: state => {
            log('vuex getters quarks')
            return state.qrks;
        },

    },
});
