<template>
	<ul class="list-unstyled text-left py-md-2">
		<li v-for="p in pages" :key="p.rank" :class="{ l1: p.level == 1, 'ml-3': p.level == 2, 'ml-5': p.level == 3 }">
			<router-link v-if="p.menu_item" :to="p.path == '' ? '/' : p.path">{{ p.title }}</router-link>
		</li>
	</ul>
</template>

<script>
export default {
	data() {
		return {};
	},
	props: {
		pages: {
			type: Array,
			function() {
				return [];
			},
		},
	},
};
</script>

<style scoped>
.l1 {
	margin-top: 1rem;
	margin-left: 0.5rem;
	text-transform: uppercase;
}

a {
	color: inherit;
}

li {
	list-style-type: none;
}

@media only screen and (max-width: 350px) {
	/* li a {
		font-size: 13px;
	} */
	.l1 {
		margin-top: 0.5rem;
	}
}
</style>
