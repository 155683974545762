// console.log('main.js');

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';

Vue.config.productionTip = false

// console.log('getpages')
store.dispatch('getPages');

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
