export default {
    strip_tags: function (input, allowed) {
        //   example 1: strip_tags('<p>Kevin</p> <br /><b>van</b> <i>Zonneveld</i>', '<i><b>')
        //   returns 1: 'Kevin <b>van</b> <i>Zonneveld</i>'
        //   example 2: strip_tags('<p>Kevin <img src="someimage.png" onmouseover="someFunction()">van <i>Zonneveld</i></p>', '<p>')
        //   returns 2: '<p>Kevin van Zonneveld</p>'
        //   example 3: strip_tags("<a href='http://kvz.io'>Kevin van Zonneveld</a>", "<a>")
        //   returns 3: "<a href='http://kvz.io'>Kevin van Zonneveld</a>"
        //   example 4: strip_tags('1 < 5 5 > 1')
        //   returns 4: '1 < 5 5 > 1'
        //   example 5: strip_tags('1 <br/> 1')
        //   returns 5: '1  1'
        //   example 6: strip_tags('1 <br/> 1', '<br>')
        //   returns 6: '1 <br/> 1'
        //   example 7: strip_tags('1 <br/> 1', '<br><br/>')
        //   returns 7: '1 <br/> 1'

        if (input != undefined) {
            // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
            allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('')

            var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi
            var commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi

            return input.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
                return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : ''
            })
        }
    },

    get_tag(input) {
        // console.log(utils.get_tag('<h1>blabla</h1>'));
        // console.log(utils.get_tag('<h1> blabla </h1>'));
        // console.log(utils.get_tag('<h1> bla bla </h1>'));
        // console.log(utils.get_tag('<h1>bla bla</h1>'));
        // console.log(utils.get_tag('<img src="aa" />'));

        let i0 = input.indexOf('<');
        let i1 = input.indexOf('>');
        let i2 = input.indexOf(' ');
        // console.log(input, i0, i1, i2)

        let tag = '';
        if (i2 < 0)
            tag = input.substring(i0 + 1, i1);
        else
            tag = input.substring(i0 + 1, (i1 < i2) ? i1 : i2);

        console.log(input + '    =>     |' + tag + '|');

        return tag;
    },

    get_src(input) {
        var str = input;
        var regex = /<img.*?src=['"](.*?)['"]/;
        var src = regex.exec(str)[1];

        // console.log('get_src', str, src);
        return src;
    },

    add_tag(input, tag) {
        let s = '<' + tag + '>' + input + '</' + tag + '>'

        return s;
    },

    img_fluid(input) {
        let str = input.slice(0);
        let img = '<img src="' + this.get_src(str) + '" class="img-fluid w-100" />';

        // console.log('img_fluid', input, img);
        return img;
    },

    sortByRank(arr) {
        // console.log('arr in', arr)
        // sort by rank
        arr.sort(function (a, b) {
            return a.rank - b.rank;
        });
        // console.log('arr out', arr)
    },
}


